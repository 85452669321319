



const FaqPage = () => {
    return <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100vw', height:'100vh'}}>
        <h1>Em Construção</h1>
    </div>
}

export default FaqPage;